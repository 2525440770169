import React from 'react';
import PropTypes from 'prop-types';
import SiteLowerFooterLogo from './SiteLowerFooterLogo/SiteLowerFooterLogo';
import SiteLowerFooterMenu from './SiteLowerFooterMenu/SiteLowerFooterMenu';
import SiteLowerFooterCopyright from './SiteLowerFooterCopyright/SiteLowerFooterCopyright';
import './SiteLowerFooter.scss';
import { useRootSelector } from '../../../../../store/hooks';
import { selectTenantConfig } from '../../../../../store/features/tenantConfig';

const informaConnectLogo = {
  link: 'https://informaconnect.com/',
  url: '/images/btyb_informa_connect_logo_white.png',
  alt: 'Informa Connect',
};

const informaConnectHomeLink = {
  title: 'event.home-page',
  href: 'https://informaconnect.com/',
  target: '_blank',
};

const informaTechHomeLink = {
  title: 'event.home-page',
  href: 'https://tech.informa.com/',
  target: '_blank',
};

const siteContactLink = {
  title: 'event.contact',
  href: '/contact/',
};

const informaPrivacyPolicyLink = {
  title: 'event.privacy-centre',
  href: 'https://www.informa.com/privacy-policy/',
  target: '_blank',
};

const parentTermsOfUseLink = {
  title: 'event.terms-of-use',
  href: '/terms-of-use',
  parent: true,
};

const siteTermsAndConditionsLink = {
  title: 'event.terms-and-conditions',
  href: '/terms-and-conditions',
};

const informaTermsAndConditionsLink = {
  title: 'event.terms-and-conditions',
  href: 'https://informaconnect.com/terms-and-policies/',
  target: '_blank',
};

const ccpaLink = {
  title: 'event.ccpa',
  href: 'https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c1f53e84-9f05-4169-a854-85052b63c50b/5f26b553-52cc-4973-a761-295d5634a6b6.html',
  target: '_blank',
};

function getLowerFooterConfig(tenantId) {
  switch (tenantId) {
    case 'FANEXPOHQ':
    case 'ONEOFAKINDSHOW':
    case 'INTERIORDESIGNSHOW':
    case 'ARTTORONTO':
    case 'THEARTISTPROJECT':
    case 'MEFCC':
      return {
        logo: informaConnectLogo,
        menu: [
          {
            title: 'Privacy Policy',
            href: 'https://www.informa.com/generic-content/privacy-policy/',
          },
          {
            title: 'Cookie Policy',
            href: 'https://www.informa.com/generic-content/cookie-policy/',
          },
          {
            title: 'Terms of Use',
            href: 'https://www.informa.com/generic-content/terms--conditions/',
          },
        ],
      };

    case 'NETWORKXEVENT':
    case 'NETWORKXSERIES':
      return {
        logo: informaConnectLogo,
        menu: [
          informaTechHomeLink,
          siteContactLink,
          informaPrivacyPolicyLink,
          parentTermsOfUseLink,
          informaTermsAndConditionsLink,
          ccpaLink,
        ],
      };

    case 'GDCONF':
      return {
        logo: informaConnectLogo,
        menu: [
          informaTechHomeLink,
          siteContactLink,
          informaPrivacyPolicyLink,
          parentTermsOfUseLink,
          ccpaLink,
        ],
      };

    case 'KNECT365':
    case 'THEMSPSUMMIT':
    case 'ENTERPRISECONNECT':
    case 'IWCEEXPO':
      return {
        logo: informaConnectLogo,
        menu: [
          informaTechHomeLink,
          siteContactLink,
          informaPrivacyPolicyLink,
          parentTermsOfUseLink,
          informaTermsAndConditionsLink,
          ccpaLink,
        ],
      };

    default:
      return {
        logo: informaConnectLogo,
        menu: [
          informaConnectHomeLink,
          siteContactLink,
          informaPrivacyPolicyLink,
          parentTermsOfUseLink,
          siteTermsAndConditionsLink,
          ccpaLink,
        ],
      };
  }
}

/**
 * WHITE LABEL
 */
export default function SiteLowerFooter({ bgColor }) {
  const { id: tenantId } = useRootSelector(selectTenantConfig);
  const lowerFooter = getLowerFooterConfig(tenantId);

  return (
    <div
      className="footer-lower"
      style={{ backgroundColor: bgColor }}
      data-testid="lower-footer"
    >
      <div className="container">
        <div className="footer-lower__wrapper">
          <div className="footer-lower__logo">
            <SiteLowerFooterLogo lowerFooter={lowerFooter} />
          </div>
          <div className="footer-lower__menu">
            <SiteLowerFooterMenu lowerFooter={lowerFooter} />
          </div>
        </div>
        <SiteLowerFooterCopyright />
      </div>
    </div>
  );
}

SiteLowerFooter.propTypes = {
  bgColor: PropTypes.string,
};
