import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { renderHTMLString } from '../../../helpers';
import { withBrand } from '../../../providers/BrandProvider';
import './HeadingSection.scss';

/**
 * WHITE LABEL
 */
export function HeadingSection(props) {
  const {
    title,
    text,
    brand: { secondaryColor, accentColor } = {},
    columnWrap = 12,
    isBrand = true,
    titleSize = 'H1',
    largeContainer,
  } = props;

  const componentClassNames = classNames('c-heading-section', {
    'c-heading-section--branded': isBrand,
  });

  const containerClassName = classNames('container', {
    'w-large': largeContainer,
  });

  const Header = titleSize.toLowerCase();

  return (
    <section
      className={componentClassNames}
      style={{ backgroundColor: isBrand ? secondaryColor : null }}
    >
      <div className={containerClassName}>
        <div className="row">
          <div className={`col-xs-12 col-md-${columnWrap}`}>
            {title && (
              <Header className="c-heading-section__title">{title}</Header>
            )}
            {text && (
              <div
                className="c-heading-section__text"
                style={{ color: isBrand ? accentColor : null }}
              >
                {renderHTMLString(text)}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

HeadingSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string,
  columnWrap: PropTypes.number,
  isBrand: PropTypes.bool,
  largeContainer: PropTypes.bool,
  brand: PropTypes.shape({
    accentColor: PropTypes.string,
    secondaryColor: PropTypes.string,
  }),
};

HeadingSection.defaultProps = {
  largeContainer: false,
};

export default withBrand(HeadingSection);
