import { configureStore } from '@reduxjs/toolkit';
import fetch from 'cross-fetch';
import qs from 'qs';

import { isBrowser, isPreview } from '../helpers';
import MultipleAPIService from '../services/MultipleAPIService';
import rootReducer from './reducers';

import AjaxService from '../services/AjaxService';
import { processRejectedValue } from './features/generic';

import v1CommerceService from '../api/CommerceService/v1';
import v1ContentService from '../api/ContentService/v1';
import v1LeadGenService from '../api/LeadGenService/v1';
import v1DiscoveryService from '../api/DiscoveryService/v1';

let preloadedState = {};
if (isBrowser() && window.__REDUX_STORE__) {
  preloadedState = window.__REDUX_STORE__;
}

export default function createStore(
  cfg,
  clientConfig,
  serverConfig,
  logger,
  req,
) {
  const preview = isPreview(cfg.location.hostname);

  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            ajax: new AjaxService({
              logger,
              preview,
              serverConfig,
            }),
            headers: {
              'Accept-Language': cfg.i18n.language,
              'Published-State': preview ? 'Draft' : 'Published',
            },
            processRejectedValue,

            Api: function () {
              return new MultipleAPIService({
                services: {
                  CommerceService: {
                    v1: v1CommerceService,
                  },
                  ContentService: {
                    v1: v1ContentService,
                  },
                  LeadGenService: {
                    v1: v1LeadGenService,
                  },
                  DiscoveryService: {
                    v1: v1DiscoveryService,
                  }
                },
              }).init(cfg, clientConfig, serverConfig, logger, preview, req);
            },
            fetch,
            qs,
            i18n: cfg.i18n,
            location: cfg.location,
            req,
          },
        },
      }),
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}
