import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import ErrorBoundary from '../../../common/ErrorBoundary';

const AccordionModule = loadable(() =>
  import(
    /* webpackChunkName: "AccordionModule" */ '../../../styled/siteBuilderModules/AccordionModule'
  ),
);
const AlertBannerModule = loadable(() =>
  import(
    /* webpackChunkName: "AlertBannerModule" */ '../../../styled/siteBuilderModules/AlertBannerModule'
  ),
);
const AllSecureModule = loadable(() =>
  import(
    /* webpackChunkName: "AllSecureModule" */ '../../../styled/siteBuilderModules/AllSecureModule'
  ),
);
const AudioModule = loadable(() =>
  import(
    /* webpackChunkName: "AudioModule" */ '../../../styled/siteBuilderModules/AudioModule'
  ),
);
const ColumnTextModule = loadable(() =>
  import(
    /* webpackChunkName: "ColumnTextModule" */ '../../../styled/siteBuilderModules/ColumnTextModule'
  ),
);
const EmailOptInModule = loadable(() =>
  import(
    /* webpackChunkName: "EmailOptInModule" */ '../../../styled/siteBuilderModules/EmailOptInModule'
  ),
);
const GrowTixCategoryModule = loadable(() =>
  import(
    /* webpackChunkName: "GrowTixCategoryModule" */ '../../../styled/siteBuilderModules/GrowTixCategoryModule'
  ),
);
const GrowTixGuestModule = loadable(() =>
  import(
    /* webpackChunkName: "GrowTixGuestModule" */ '../../../styled/siteBuilderModules/GrowTixGuestModule'
  ),
);
const FestivalProCategoryModule = loadable(() =>
  import(
    /* webpackChunkName: "FestivalProCategoryModule" */ '../../../styled/siteBuilderModules/FestivalProCategoryModule'
  ),
);
const BevyModule = loadable(() =>
  import(
    /* webpackChunkName: "BevyModule" */ '../../../styled/siteBuilderModules/BevyModule'
  ),
);
const FileDownloadModule = loadable(() =>
  import(
    /* webpackChunkName: "FileDownloadModule" */ '../../../styled/siteBuilderModules/FileDownloadModule'
  ),
);
const HeroModule = loadable(() =>
  import(
    /* webpackChunkName: "HeroModule" */ '../../../styled/siteBuilderModules/HeroModule'
  ),
);
const IframeModule = loadable(() =>
  import(
    /* webpackChunkName: "IframeModule" */ '../../../styled/siteBuilderModules/IframeModule'
  ),
);
const ImageModule = loadable(() =>
  import(
    /* webpackChunkName: "ImageModule" */ '../../../styled/siteBuilderModules/ImageModule'
  ),
);
const ImageGalleryModule = loadable(() =>
  import(
    /* webpackChunkName: "ImageGalleryModule" */ '../../../styled/siteBuilderModules/ImageGalleryModule'
  ),
);
const RelatedEventsModule = loadable(() =>
  import(
    /* webpackChunkName: "RelatedEventsModule" */ '../../../styled/siteBuilderModules/RelatedEventsModule'
  ),
);
const SpeakersModule = loadable(() =>
  import(
    /* webpackChunkName: "SpeakersModule" */ '../../../styled/siteBuilderModules/SpeakersModule'
  ),
);
const SPEXCategoryModule = loadable(() =>
  import(
    /* webpackChunkName: "SPEXCategoryModule" */ '../../../styled/siteBuilderModules/SPEXCategoryModule'
  ),
);
const StatisticsModule = loadable(() =>
  import(
    /* webpackChunkName: "StatisticsModule" */ '../../../styled/siteBuilderModules/StatisticsModule'
  ),
);
const TestimonialModule = loadable(() =>
  import(
    /* webpackChunkName: "TestimonialModule" */ '../../../styled/siteBuilderModules/TestimonialModule'
  ),
);
const TicketsModule = loadable(() =>
  import(
    /* webpackChunkName: "TicketsModule" */ '../../../styled/siteBuilderModules/TicketsModule'
  ),
);
const VenueModule = loadable(() =>
  import(
    /* webpackChunkName: "VenueModule" */ '../../../styled/siteBuilderModules/VenueModule'
  ),
);
const VideoModule = loadable(() =>
  import(
    /* webpackChunkName: "VideoModule" */ '../../../styled/siteBuilderModules/VideoModule'
  ),
);
const VideoGalleryModule = loadable(() =>
  import(
    /* webpackChunkName: "VideoGalleryModule" */ '../../../styled/siteBuilderModules/VideoGalleryModule'
  ),
);
const CTAButtonGroupModule = loadable(() =>
  import(
    /* webpackChunkName: "CTAButtonGroupModule" */ '../../../styled/siteBuilderModules/CTAButtonGroupModule'
  ),
);
const ZoomableImageModule = loadable(() =>
  import(
    /* webpackChunkName: "ZoomableImageModule" */ '../../../styled/siteBuilderModules/ZoomableImageModule'
  ),
);
const DropColumnModule = loadable(() =>
  import(
    /* webpackChunkName: "DropColumnModule" */ '../../../styled/siteBuilderModules/DropColumnModule'
  ),
);
const HeroCarouselModule = loadable(() =>
  import(
    /* webpackChunkName: "HeroCarouselModule" */ '../../../styled/siteBuilderModules/HeroCarouselModule'
  ),
);
const CourseOptionDisplayModule = loadable(() =>
  import(
    /* webpackChunkName: "CourseOptionDisplayModule" */ '../../../styled/siteBuilderModules/CourseOptionDisplayModule'
  ),
);
const CourseOptionDisplayHeroModule = loadable(() =>
  import(
    /* webpackChunkName: "CourseOptionDisplayHeroModule" */ '../../../styled/siteBuilderModules/CourseOptionDisplayHeroModule'
  ),
);

const HtmlBlockModule = loadable(() =>
  import(
    /* webpackChunkName: "CourseOptionDisplayHeroModule" */ '../../../styled/siteBuilderModules/HtmlBlockModule'
  ),
);

export default function SiteSection(props) {
  const { section: { type } = {}, section } = props;

  let Module;
  switch (type) {
    case 'accordion_page_section':
      Module = AccordionModule;
      break;

    case 'event_alert_banner_section':
      Module = AlertBannerModule;
      break;

    case 'all_secure_section':
      Module = AllSecureModule;
      break;

    case 'event_audio_section':
      Module = AudioModule;
      break;

    case 'event_custom_page_section':
      Module = ColumnTextModule;
      break;

    case 'event_lead_generation_section':
      Module = EmailOptInModule;
      break;

    case 'event_file_download_section':
      Module = FileDownloadModule;
      break;

    case 'event_hero_section':
      Module = HeroModule;
      break;

    case 'iframe_section':
      Module = IframeModule;
      break;

    case 'event_image_section':
      Module = ImageModule;
      break;

    case 'event_image_gallery_section':
      Module = ImageGalleryModule;
      break;

    case 'event_related_event_section':
      Module = RelatedEventsModule;
      break;

    case 'event_promoted_speakers_section':
      Module = SpeakersModule;
      break;

    case 'event_sponsor_category_section':
      Module = SPEXCategoryModule;
      break;

    case 'event_statistic_section':
      Module = StatisticsModule;
      break;

    case 'event_testimonial_section':
      Module = TestimonialModule;
      break;

    case 'tickets_section':
      Module = TicketsModule;
      break;

    case 'event_venue_section':
      Module = VenueModule;
      break;

    case 'event_video_section':
      Module = VideoModule;
      break;

    case 'event_video_gallery_section':
      Module = VideoGalleryModule;
      break;

    case 'floor_plan_section':
      Module = ZoomableImageModule;
      break;

    case 'growtix_category_section':
      Module = GrowTixCategoryModule;
      break;

    case 'festival_pro_category_section':
      Module = FestivalProCategoryModule;
      break;

    case 'bevy_section':
      Module = BevyModule;
      break;

    case 'cta_button_group_section':
      Module = CTAButtonGroupModule;
      break;

    case 'drop_column_section':
      Module = DropColumnModule;
      break;

    case 'growtix_guest_section':
      Module = GrowTixGuestModule;
      break;

    case 'hero_carousel_section':
      Module = HeroCarouselModule;
      break;

    case 'course_option_display_section':
      Module = CourseOptionDisplayModule;
      break;

    case 'course_option_display_hero_section':
      Module = CourseOptionDisplayHeroModule;
      break;

    case 'html_block_section':
      Module = HtmlBlockModule;
      break;

    default:
      return null;
  }

  return (
    <ErrorBoundary>
      <Module section={section} />
    </ErrorBoundary>
  );
}

SiteSection.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
